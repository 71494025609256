import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1faf24da"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "ref-link"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinkIcon = _resolveComponent("LinkIcon")!
  const _component_Popup = _resolveComponent("Popup")!

  return (this.hasPaymentNew && this.translateData)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "ref-link__link",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.modalOpen && _ctx.modalOpen(...args)))
        }, [
          _createTextVNode(_toDisplayString(this.translateData.referral_link) + " ", 1),
          _createVNode(_component_LinkIcon)
        ]),
        (_ctx.isModalOpen)
          ? (_openBlock(), _createBlock(_component_Popup, {
              key: 0,
              "on-close": _ctx.handleCloseModal
            }, null, 8, ["on-close"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}